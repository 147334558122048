import React from 'react'
import cover1 from '../Assets/cover1.jpg'
import cover2 from '../Assets/Cover2.jpg'
import cover3 from '../Assets/10001.jpg'
import Gallery from '../Gallery/Gallery'
import * as Icon from 'react-bootstrap-icons'
import './Branches.css'

const Branches = () => {
  return (
    <>
    <div className='Branches' id='Branches'>
        <p className="head">Main Branch</p>
        <div className="Branch-sect main">
                <div className="Branch-img-cont">
                    <img src={cover3} alt="" />
                    <p>Mountain of Fire and miracle ministries, tentmakers, belfast, northern ireland, uK</p>
                </div>
                <div className="branch-inf-cont">
                    <p>36 Ormeau Rd, Belfast BT7 2EA, UK</p>
                    <hr />
                    <p>minister in charge : <span className='inf-head'>Pastor Joseph Awodola</span></p>
                    <p><Icon.TelephoneFill/> 0751 159 2399 </p>
                </div>
            </div>
            <p className="head">Other Branches</p>
        <div className="Branch-cont">
            <div className="Branch-sect">
                <div className="Branch-img-cont">
                    <img src={cover1} alt="" />
                    <p>Mountain of Fire and miracle ministries, tentmakers, UK</p>
                </div>
                <div className="branch-inf-cont">
                    <p>76 steven road, dagenham RM8 2QL</p>
                    <hr />
                    <p>minister in charge : <span className='inf-head'>Pastor Femi bamiduro</span></p>
                    <p><Icon.TelephoneFill/> +44 770 8050 707 </p>
                </div>
            </div>
            
            <div className="Branch-sect">
                <div className="Branch-img-cont">
                    <img src={cover2} alt="" />
                    <p>Mountain of Fire and miracle ministries, Daniel Center, UK</p>
                </div>
                <div className="branch-inf-cont">
                    <p>Manor Park Community Center, Villiers Road, slough SL2 1NP</p>
                    <hr />
                    <p>minister in charge : <span className='inf-head'>Pastor Aham igbokwe</span></p>
                    <p><Icon.TelephoneFill/> +44 743 6623 572</p>
                </div>
            </div>
        </div>
    </div>
    <Gallery/>
    </>
  )
}

export default Branches