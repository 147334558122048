import React from "react";
import menCover from '../Assets/Branches/menCover.jpg'
import womenCover from '../Assets/Branches/WomenCover.jpg'
import familyCover from '../Assets/Branches/FamilyCover.jpg'
import menGallery from './menGallery.json'
import womenGallery from './womenGallery.json'
import familyGallery from './familyGallery.json'
import { Navigation, Pagination, A11y, EffectFlip, Keyboard } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Parallax } from "react-parallax";
import gall from '../Assets/people-2599550_1920.jpg'
import './Gallery.css'

const Gallery = () => {

    return (
        <>
        <div className='Gallery' id="Gallery">
        <Parallax bgImage={gall} strength={-150}  className="paragal">
            <h1 className="gall-intro">Gallery</h1>
        </Parallax>

            <div className="gallery-sect-cont">
                <div className="gallery-sect" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    <img src={menCover} alt="..." />
                    <p>MFMTENTMAKERS BELFAST MEN OF VALOUR <br /> ( Click for gallery )</p>
                </div>
                <div className="gallery-sect" data-bs-toggle="modal" data-bs-target="#womenModal">
                    <img src={womenCover} alt="..." />
                    <p>MFMTENTMAKERS BELFAST VIRTUOUS WOMEN <br /> ( Click for gallery )</p>
                </div>
                <div className="gallery-sect" data-bs-toggle="modal" data-bs-target="#familyModal">
                    <img src={familyCover} alt="..." />
                    <p>MFMTENTMAKER FAMILY OF PEACE AND DELIVERANCE <br /> ( Click for gallery )</p>
                </div>
            </div>

            <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
            <div className="modal-dialog custom-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title " id="exampleModalLabel">MFMTENTMAKERS BELFAST MEN OF VALOUR </h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
            <Swiper
            modules={[Navigation, Pagination, A11y, Keyboard, EffectFlip]}
            effect='flip'
            navigation={true}
            keyboard={{ enabled: true }}
            pagination={{ clickable: true }}
            spaceBetween={30}
            loop={true}
            slidesPerView={1}
            className="Swiper"
            >
            {menGallery.map((item) => (
                <SwiperSlide key={item.id} className="SwiperSlide" id={item.new}>
                    <p>New</p>
                    <img src={item.imgSrc} alt={item.Name} />
                </SwiperSlide>
                ))}
            </Swiper>
                </div>
                </div>
            </div>
            </div>
        </div>
                


<div className="modal fade" id="womenModal" tabIndex="-1" aria-labelledby="womenModalLabel" aria-hidden="true">
    <div className="modal-dialog custom-dialog">
        <div className="modal-content">
            <div className="modal-header">
                <h1 className="modal-title" id="womenModalLabel">MFMTENTMAKERS BELFAST VIRTUOUS WOMEN </h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
                <Swiper
                    modules={[Navigation, Pagination, A11y, Keyboard, EffectFlip]}
                    effect='flip'
                    navigation={true}
                    keyboard={{ enabled: true }}
                    pagination={{ clickable: true }}
                    spaceBetween={30}
                    loop={true}
                    slidesPerView={1}
                    className="Swiper"
                >
                    {womenGallery.map((item) => (
                        <SwiperSlide key={item.id} className="SwiperSlide" id={item.new}>
                            <p>New</p>
                            <img src={item.imgSrc} alt={item.Name} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    </div>
</div>

<div className="modal fade" id="familyModal" tabIndex="-1" aria-labelledby="familyModalLabel" aria-hidden="true">
    <div className="modal-dialog custom-dialog">
        <div className="modal-content">
            <div className="modal-header">
                <h1 className="modal-title" id="familyModalLabel">MFMTENTMAKER FAMILY OF PEACE AND DELIVERANCE </h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
                <Swiper
                    modules={[Navigation, Pagination, A11y, Keyboard, EffectFlip]}
                    effect='flip'
                    navigation={true}
                    keyboard={{ enabled: true }}
                    pagination={{ clickable: true }}
                    spaceBetween={30}
                    loop={true}
                    slidesPerView={1}
                    className="Swiper"
                >
                    {familyGallery.map((item) => (
                        <SwiperSlide key={item.id} className="SwiperSlide" id={item.new}>
                            <p>New</p>
                            <img src={item.imgSrc} alt={item.Name} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    </div>
</div>


    </>
    )
}

export default Gallery;
