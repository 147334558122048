import React from 'react'
import './Sect.css'
import pst1 from '../Assets/pastor1.jpg'
import bible1 from '../Assets/bible1.jpg'

const Sect = () => {
  return (
    <div className='Sect' id='Who-we-are'>
      <div className="sect-cont">
        <img src={pst1} alt="" />
        <p>"We are not defined by the hell we're going through but by the heaven we are going to"</p>
        <p className='speaker'> - Daniel K Olukoya.</p>
      </div>


      <div className="sect-cont">
        <div className="img-cont">
          <img src={bible1} alt="bible" />
        </div>
        <div className="sect2-inf">
          <div className="sect2-cont">
            <p className="head">Our Vision</p>
            <p>In every household, a member of Mountain of Fire and Miracles Ministries all over the world.</p>
          </div>
          <div className="sect2-cont">
            <p className="head">Our Mission</p>
            <p>Capturing the world for Christ through Deliverance, Evangelism and Church Planting.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sect