import Header from './Components/Header/Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import Home from './Components/Pages/Home/Home';
import Sect from './Components/Pages/Sect/Sect';
import Overseers from './Components/Pages/OverSeers/Overseers';
import Branches from './Components/Pages/Branches/Branches';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-flip';
import 'swiper/css';
import './App.css';
import Discover from './Components/Pages/Discover/Discover';
import Footer from './Components/Footer/Footer';

function App() {
  return (
    <>
      <Header/>
      <Home/>
      <Sect/>
      <Overseers/>
      <Branches/>
      <Discover/>
      <Footer/>
    </>
  )
}

export default App;
