import React from 'react'
import { Parallax } from 'react-parallax'
import mainImg from '../Assets/main-img.jpg'
import './Home.css'

const Home = () => {
  return (
    <Parallax strength={200} bgImageAlt='backg' bgImage={mainImg}>
    <div className='Home' id='Home'>
        <div className="Home-cont">
            <div className="txt-cont">
                <p>Worship with</p>
                <p className="big">God's Family</p>
                <p>we serve the god of elijah, the god that answereth by fire</p>
            </div>
            <div className="btn-cont">
                <a href="#Contact"><button>Join us for church</button></a>
                <a href="#Who-we-are"><button>our mission</button></a>
            </div>
        </div>
    </div>
    </Parallax>
  )
}

export default Home