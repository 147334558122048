import React, { useEffect, useState } from 'react';
import logo from '../Pages/Assets/logo.png';
import './Header.css';
import Headroom from 'react-headroom';

const Header = () => {
  const [isActive, setIsActive] = useState(false);
  const [currentSection, setCurrentSection] = useState('Home');
  const [isGradVisible, setIsGradVisible] = useState(false);

  useEffect(() => {
    const handleHamburgerClick = () => {
      setIsActive((prevState) => !prevState);
      setIsGradVisible((prevState) => !prevState);
    };

    const handleScroll = () => {
      const sectionEls = document.querySelectorAll('.Home, .Sect, .Overseers, .Branches, .Gallery, .Discover');
    
      let currentSectionId = 'Home';
    
      sectionEls.forEach((sectionEl) => {
        const rect = sectionEl.getBoundingClientRect();
    
        if (rect.top <= 270 && rect.bottom >= 200) {
          currentSectionId = sectionEl.id;
        }
      });
    
      setCurrentSection(currentSectionId);
    };

    const hamburgerElement = document.querySelector('.hamburger');

    if (hamburgerElement) {
      hamburgerElement.addEventListener('click', handleHamburgerClick);
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      if (hamburgerElement) {
        hamburgerElement.removeEventListener('click', handleHamburgerClick);
      }
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Headroom className={`Header-cont${isGradVisible ? ' extended' : ''}`}>
        <div className="Header" id="home">
          <div className="logo-cont">
              <img src={logo} alt="MFM logo" />
              <p>MFM TentMakers Belfast Ministry</p>
              <p>Mountain of fire and miracles ministries tent makers Belfast ministry</p>
          </div>
          <div className="nav-links">
            <a href="#Who-we-are" className={currentSection === 'Who-we-are' ? 'link active' : 'link'}>Who We Are</a>
            <a href="#Overseers" className={currentSection === 'Overseers' ? 'link active' : 'link'}>Overseers</a>
            <a href="#Branches" className={currentSection === 'Branches' ? 'link active' : 'link'}>Locations</a>
            <a href="#Gallery" className={currentSection === 'Gallery' ? 'link active' : 'link'}>Gallery</a>
            <a href="#Contact" className={currentSection === 'Contact' ? 'link active' : 'link'}>Our Links</a>
          </div>
          <div className={`hamburger ${isActive ? 'is-active' : ''}`} data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-controls="collapseExample">
            <div className="hamburger__container">
              <div className="hamburger__inner"></div>
              <div className="hamburger__hidden"></div>
            </div>
          </div>
        </div>
        <div className={`grad2 ${isGradVisible ? 'visible' : ''}`}>
          <div className="nav-links">
            <a href="#Who-we-are" className={currentSection === 'Who-we-are' ? 'link active' : 'link'}>Who We Are</a>
            <a href="#Overseers" className={currentSection === 'Overseers' ? 'link active' : 'link'}>Overseers</a>
            <a href="#Branches" className={currentSection === 'Branches' ? 'link active' : 'link'}>Locations</a>
            <a href="#Gallery" className={currentSection === 'Gallery' ? 'link active' : 'link'}>Gallery</a>
            <a href="#Contact" className={currentSection === 'Contact' ? 'link active' : 'link'}>Our Links</a>
          </div>
        </div>
      </Headroom>
    </>
  );
};

export default Header;
