import React from 'react'
import * as Icon from 'react-bootstrap-icons'
import './Footer.css'

const Footer = () => {
  return (
    <div className='Footer'>
        <div className="foot-sect">
            <div className="foot-info">
                <p><span>Copyright © 2024</span> - Mountain of Fire and Miracles Ministries Tent Makers Pastorial Ministry. <br /> All Rights Reserved.</p>
            </div>
        </div>
        <div className="foot-sect">
            <a href="#Home"><Icon.ArrowUpCircle className='foot-ico'/></a>
        </div>
        <div className="foot-sect">
        <div className="foot-icons">
                    <a href='https://www.facebook.com/profile.php?id=61553382904053' target='_blank' rel='noreferrer'><Icon.Facebook className='foot-ico'/></a>
                    <a href='https://www.instagram.com/mfmtentmakersbelfast?igshid=NGVhN2U2NjQ0Yg%3D%3D&utm_source=qr' target='_blank' rel='noreferrer'><Icon.Instagram className='foot-ico'/></a>
                    <a href='#ggg'><Icon.Youtube className='foot-ico'/></a>
                </div>
        </div>
    </div>
  )
}

export default Footer