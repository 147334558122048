import React from 'react'
import * as Icon from 'react-bootstrap-icons'
import './Discover.css'

const Discover = () => {
  return (
    <div className='Discover' id='Contact'>
        <h1>Discover our communities now</h1>
        <div className="disc-cont">
            <div className="disc">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d577.978519325586!2d-5.924793550988997!3d54.58788807162628!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x486108fc89348cdf%3A0x7e7ae3d1cf64b80!2s36%20Ormeau%20Rd%2C%20Belfast%20BT7%202EA%2C%20UK!5e0!3m2!1sen!2sng!4v1703326522637!5m2!1sen!2sng"  height="450" style={{border : 0}} loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='map'></iframe>
            </div>
            <div className="disc">
                <p>Join the Conversation</p>
                <div className="disc-icons">
                    <a href='https://www.facebook.com/profile.php?id=61553382904053' target='_blank' rel='noreferrer'><Icon.Facebook className='disc-ico'/></a>
                    <a href='https://www.instagram.com/mfmtentmakersbelfast?igshid=NGVhN2U2NjQ0Yg%3D%3D&utm_source=qr' target='_blank' rel='noreferrer'><Icon.Instagram className='disc-ico'/></a>
                    <a href='https://www.youtube.com/@MFMMinistries'><Icon.Youtube className='disc-ico'/></a>
                </div>
            </div>
            <div className="disc">
                <p>Grow your faith</p>
                <a href="mailto:mfmtentmakersbelfast@gmail.com">
                    <button>Chat With Us</button>
                </a>
            </div>
        </div>
    </div>
  )
}

export default Discover