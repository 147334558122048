import React, { useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
import data from './Data.json';
import { Parallax } from 'react-parallax';
import cover from '../Assets/cover1.jpg'
import './Overseers.css';

const Overseers = () => {
  const [search, setSearch] = useState('');

  return (
    <Parallax strength={150} bgImageAlt='Praise' bgImage={cover} >
       <div className='Overseers' id='Overseers'>
        <div className="Oversee-sect">
          <p className="head">Overseers</p>
          <p>Church overseers play a vital role in guiding and shepherding the congregation. Charged with spiritual leadership, they provide guidance, support, and pastoral care to members.</p>
          <p className='title'>Co-ordinator Worldwide: <span>Pastor Clement Imoru.</span></p>
        </div>


        <div className="Oversee-sect">
       <div className="data-head">
        <p className="title">Info</p>
          <div className="head-inf">
          <input
          onChange={(e) => setSearch(e.target.value)}
          type="text"
          className='search'
          placeholder='Search Pastor...'
          />
          <Icon.Search className='head-inf-ico'/>
          </div>
          </div>
          <div className="data-cont">
          {data
            .filter((item) => {
              const searchData = search.toLowerCase();
              const postData = item.post.toLowerCase();
              return searchData === '' ? item : postData.includes(searchData);
            })
              .map((item, i) => (
                <div className="data-sect" key={i}>
                  <p>{item.post}</p>
                  <p>{item.regions}</p>
                  <p>{item['phone-no']}</p>
                </div>
                ))
              }
          </div>
          </div>
      </div>
    </Parallax>
  );
}

export default Overseers;
